@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-black font-bold;
  }
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: rgb(41, 6, 84);
    font-weight: 600;
    text-decoration: 1px underline rgb(41, 9, 84);
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.loader {
  width: 100px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: black;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

input:focus {
  outline: none;
}

@media (max-width: 667px) {
  .menu-button {
    display: block;
  }

  .menu-items {
    display: none;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ddd;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: 230px; /* Adjust this to control the visible items */
    /* overflow-y: auto; Make it scrollable */
  }

  .menu-items.open {
    display: flex;
  }

  .menu-items a {
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: black; /* Set default text color to black */
  }

  .menu-items a:hover {
    background-color: #f1f1f1;
    color: darkpurple; /* Set hover text color to dark purple */
  }

  .menu-items .active a {
    color: darkpurple; /* Set active text color to dark purple */
  }
}
.user-list-scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.user-list-scroll-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.user-list-scroll-bar::-webkit-scrollbar-thumb {
  background: rgb(39 116 175);
  border-radius: 10px;
}
@media (max-width: 768px) {
  .dropdown-content {
    font-size: 0.875rem; /* Adjust font size for mobile */
    padding: 0.5rem 0.75rem; /* Reduce padding */
  }

  .dropdown-grid {
    grid-template-columns: 1fr; /* Stack items in a single column on mobile */
    gap: 0.5rem; /* Reduce gap between items */
  }

  .dropdown-header {
    font-size: 1rem; /* Slightly smaller font for headers */
  }
}

/* Handle on hover */
